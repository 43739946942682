/* for hiding process is not defined bug from console */
body
  > iframe[style*='2147483647']:not([id='webpack-dev-server-client-overlay']) {
  display: none;
}
.chart {
  height: 320px !important;
  width: 320px !important;
  justify-items: center;
  margin: auto;
}

.drawer {
  position: fixed;
  z-index: 9999;
  transition: width 0s ease 0.3s, height 0s ease 0.3s,
    transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer > * {
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    box-shadow 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer.drawer-open {
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer .drawer-mask {
  background: #000;
  opacity: 0;
  width: 100%;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    height 0s ease 0.3s;
}
.drawer-content-wrapper {
  position: absolute;
  background: #fff;
}
.drawer-content {
  overflow: auto;
  z-index: 1;
  position: relative;
}
.drawer-handle {
  position: absolute;
  top: 72px;
  width: 41px;
  height: 40px;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  display: none;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.drawer-handle-icon {
  width: 14px;
  height: 2px;
  background: #333;
  position: relative;
  transition: background 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer-handle-icon:before,
.drawer-handle-icon:after {
  content: '';
  display: block;
  position: absolute;
  background: #333;
  width: 100%;
  height: 2px;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer-handle-icon:before {
  top: -5px;
}
.drawer-handle-icon:after {
  top: 5px;
}
.drawer-left,
.drawer-right {
  width: 0%;
  height: 100%;
}
.drawer-left .drawer-content-wrapper,
.drawer-right .drawer-content-wrapper,
.drawer-left .drawer-content,
.drawer-right .drawer-content {
  height: 100%;
}
.drawer-left.drawer-open,
.drawer-right.drawer-open {
  width: 100%;
}
.drawer-left.drawer-open.no-mask,
.drawer-right.drawer-open.no-mask {
  width: 0%;
}
.drawer-left {
  top: 0;
  left: 0;
}
.drawer-left .drawer-handle {
  right: -40px;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 4px 4px 0;
}
.drawer-left.drawer-open .drawer-content-wrapper {
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}
.drawer-right {
  top: 0;
  right: 0;
}
.drawer-right .drawer-content-wrapper {
  right: 0;
}
.drawer-right .drawer-handle {
  left: -40px;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px 0 0 4px;
}
.drawer-right.drawer-open .drawer-content-wrapper {
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
}
.drawer-right.drawer-open.no-mask {
  right: 1px;
  transform: translateX(1px);
}
.drawer-top,
.drawer-bottom {
  width: 100%;
  height: 0%;
}
.drawer-top .drawer-content-wrapper,
.drawer-bottom .drawer-content-wrapper,
.drawer-top .drawer-content,
.drawer-bottom .drawer-content {
  width: 100%;
}
.drawer-top .drawer-content,
.drawer-bottom .drawer-content {
  height: 100%;
}
.drawer-top.drawer-open,
.drawer-bottom.drawer-open {
  height: 100%;
}
.drawer-top.drawer-open.no-mask,
.drawer-bottom.drawer-open.no-mask {
  height: 0%;
}
.drawer-top .drawer-handle,
.drawer-bottom .drawer-handle {
  left: 50%;
  margin-left: -20px;
}
.drawer-top {
  top: 0;
  left: 0;
}
.drawer-top .drawer-handle {
  top: auto;
  bottom: -40px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 4px 4px;
}
.drawer-top.drawer-open .drawer-content-wrapper {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.drawer-bottom {
  bottom: 0;
  left: 0;
}
.drawer-bottom .drawer-content-wrapper {
  bottom: 0;
}
.drawer-bottom .drawer-handle {
  top: -40px;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px 4px 0 0;
}
.drawer-bottom.drawer-open .drawer-content-wrapper {
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
}
.drawer-bottom.drawer-open.no-mask {
  bottom: 1px;
  transform: translateY(1px);
}
.drawer.drawer-open .drawer-mask {
  opacity: 0.3;
  height: 100%;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer.drawer-open .drawer-handle-icon {
  background: transparent;
}
.drawer.drawer-open .drawer-handle-icon:before {
  transform: translateY(5px) rotate(45deg);
}
.drawer.drawer-open .drawer-handle-icon:after {
  transform: translateY(-5px) rotate(-45deg);
}

/* drawer */
.drawer {
  overflow: visible;  
  z-index: 9989 !important;
}
.drawer .drawer-content-wrapper {
  width: 55vw;
  transform: translateX(0px);
}
.drawer .drawer-content-wrapper .drawer-manage {
  width: 40vw;
  /* transform: translateX(0px); */
}
.drawer .drawer-content-wrapper .drawer-handle {
  display: none !important;
}
@media (min-width: 320px) and (max-width: 767px) {
  .drawer .drawer-content-wrapper {
    width: 100vw;
  }
}

/* modal */
.custom-modal header button {
  display: none;
}

/*input tag css */
.react-tag-input {
  width: 100%;
  background: #f4f5f7;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  padding: 0;
}
.react-tag-input__input {
  font-size: 0.875rem;
  margin: 0;
  height: 3rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  background: transparent;
  color: #4c4f52;
}
.react-tag-input__input:focus {
  background: #ffffff;
}
.react-tag-input__input::placeholder,
.react-tag-input__input:-moz-placeholder,
.react-tag-input__input:-ms-input-placeholder,
.react-tag-input__input::-moz-placeholder,
.react-tag-input__input::-webkit-input-placeholder {
  font-size: 10px;
  font-weight: 400;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.react-tag-input__tag {
  margin: 0.175rem 0.375rem;
  color: #24262d;
}
.theme-dark .react-tag-input {
  color: #ebebeb;
  border-color: #4c4f52;
  background-color: #24262d;
}
.theme-dark .react-tag-input__input {
  color: #ebebeb;
}
.theme-dark .react-tag-input__input:focus {
  background-color: #24262d;
  border-color: #707275;
}

/* dropdown style */
.dropdown {
  display: none !important;
}

/* notification box css */

.notification-box {
  width: 20rem;
  height: 22rem;
}
.notification-content h6 {
  font-size: 13px;
  margin-bottom: 5px;
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.notification-content p span {
  font-size: 11px;
}

.updateBtn {
  position: fixed; 
  /* width: 100%; */
  bottom: 2px;
  text-align: end;
  right: 35px;
}

.updateBtn:hover {
  color: #fff;
  background-color: none !important;
  border-color: none !important;
  background: #3aa84f;
  border-color: #3aa84f;
  opacity: 1;
}

.css-1pahdxg-control{
  border-color: #d5d6d7 !important;
  box-shadow: none !important;
}
.css-b62m3t-container{
  height: 3rem !important;
}
.css-1s2u09g-control {
  height: 47px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.DateRangePicker__CalendarSelection {
  background-color: #0E9F6E !important;
  border: 1px solid #0E9F6E !important;
}

.DateRangePicker {
  position: absolute !important;
  background: #e5e7eb !important;
}
.react-datepicker {

  position: inherit !important;
}

.react-datepicker-wrapper {
  width: auto !important;
}
.react-datepicker__triangle {
  display: none;
}

.react-datepicker__input-container {
  width: auto !important;
  position: relative;
}

.react-datepicker__input-container:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #000 transparent transparent transparent;
}

.react-datepicker__input-container input {
  /* margin-right: 5px !important; */
  /* width: 95%; */
  padding: 5px
}
.react-datepicker__close-icon {
  outline: none !important;
}

/* .css-26l3qy-menu {
  width: 478px;
  max-width: 478px;
}

.css-1s2u09g-control {
  width: 545px;
  float: right;
} */

.css-1s2u09g-control {
  height: 47px !important;
}
.css-1pahdxg-control {
  min-height: 47px !important;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  transform: none;
  top:50%;
}
.react-datepicker__month-read-view,
.react-datepicker__year-read-view {
  display: none;
}

.select-container {
  position: relative;
}

.selected-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 8px;
}

.disabled-div {
  opacity: 0.5; /* Example: reduce opacity to indicate disabled state */
  pointer-events: none; /* Example: disable pointer events */
  cursor: not-allowed;
  /* Additional styling to visually indicate the disabled state */
}
.deliverydetails {
  position: fixed;
  display: grid;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  height: 22rem;
  width: 25rem;
  backdrop-filter: blur(8px);
}

.deliverydetails1 {
  position: relative;
  /* left: 150px; */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 46px;
  padding: 20px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  z-index: 9994;
  height: 12rem;
  width: 25rem;
  margin-bottom: 2rem;
}

.cancelButton {
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
  font-weight: bold;
  width: 35%;
}

.cancelButton:hover {
  background-color: #c0392b;
}

.rejection-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 500px;
  padding: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  z-index: 9999;
}

.rejection-reason {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

.rejection-popup button {
  display: block;
  width: 50%;
  padding: 10px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  text-align: center;
  cursor: pointer;
  float: right;
}

.rejection-popup button:hover {
  background-color: #45a049;
}


.addressleads   {
  display: table-cell;
  white-space: pre-wrap;
  line-height: 1.25rem;
  height: 3.75rem;
  overflow: hidden;
}


/* Reviews.css */
.reviews-card {
  width: 95%;
  margin: 20px;
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -33px;
}

.reviews-heading {
  font-size: 20pt;
  margin: 0;
  font-weight: 600;
}

.rating-stats {
  display: flex;
  align-items: center;
  background-color: white;
}

.total-reviews {
  font-size: 30px;
  /* margin-right: 20px; */
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.rating-graph {
  display: flex;
  align-items: flex-end;
}

.rating-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 5px;
}

.rating-bar {
  width: 40px;
  height: 100px;
  margin-bottom: 5px;
  background-color: #f9f9f9;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
}

.rating-bar-fill {
  height: 100%;
  background-color: #ffc000;
  transition: height 0.3s ease-in-out;
}

.rating-label {
  font-size: 14px;
}

.graph-filter-container {
  margin-bottom: 20px;
}

.comments-container {
  margin-top: 20px;
}

.review-item {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  /* background-color: #f9f9f9; */
  box-shadow: 2px 0 3px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.review-item p {
  margin: 5px 0;
}

.fa-star,
.fa-star-half {
  color: #ffc107;
}

.star-rating {
  color: #ffc107; /* Set the color of the stars */
  font-size: 1.3rem; /* Set the size of the stars */
  line-height: 0; /* Set the line height to align the stars vertically */
  display: inline-block; /* Set the display to inline-block to allow wrapping */
}

.button-review {
  width: 3rem;
  height: 2rem;
  border-radius: 35px;
  font-size: 7pt;
  font-weight: 800;
}

/* Styling for the tab container */
.tab-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

/* Styling for the tab buttons */
.tab-btn {
  padding: 0.5rem 1rem;
  border: none;
  background-color: #f1f1f1;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

/* Active tab button style */
.tab-btn.active {
  background-color: #34a549;
  color: #fff;
}

.red {
  background-color: #0e9f6e;
  /* Add other styles as needed */
}



/* General Styles */
.whatsapp-web-container {
  display: flex;
  height: 89vh;
  background-color: #f0f0f0;
}

/* Sidebar Styles */
.whatsapp-sidebar {
  width: 300px;
  border-right: 1px solid #ccc;
  background-color: #fff;
  overflow-y: auto;
  /* padding: 20px; */
  position: fixed;
  top: 65px;
  bottom: 0;
}

/* Sticky header for the sidebar */
.whatsapp-sidebar h2 {
  position: sticky;
  top: 0;
  padding: 10px;
  margin: 0;
  font-weight: 900;
  border-bottom: 1px solid #ccc;
  z-index: 10;
  background-color: rgb(14, 159, 110);
  color: white;
}

.contact-list li:hover {
  background-color: rgb(14, 159, 110);
  color: white;
  border-radius: 15px;
}

.contactactive {
  color: white;
  background-color: rgb(14, 159, 110);
  border-radius: 15px;
  font-weight: 900;
}

.contactsocket {
  color: white;
  background-color: rgb(97, 164, 142);
  border-radius: 15px;
  font-weight: 900;
}
.whatsapp-sidebar-tags {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
  margin-top: 15px;
}

.radio-container {
  display: flex;
  align-items: center;
}

.radio-container input[type="radio"] {
  margin-right: 5px;
}

.radio-container label {
  cursor: pointer;
}

.radio-container.active label {
  font-weight: bold;
  color: rgb(14, 159, 110);
}

.contact-list {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
}

.contact-list li {
  display: flex;
  flex-direction: row;
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}


/* .contact-list li:hover {
  background-color: #e9e9e9;
} */

.contact-list li .avatar {
  margin-right: 10px;
}

/* Loader Styles */
.loader {
  text-align: center;
  padding: 20px;
  font-size: 18px;
}

/* Message Area Styles */
.whatsapp-message-area {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* padding: 20px; */
  background-color: #fafafa;
  margin-left: 300px; /* To account for the fixed sidebar */
  position: relative;
  height: 100%;
}

.whatsapp-message-header {
  background-color: rgb(14, 159, 110); /* Header background color */
  color: #fff;
  padding: 10px;
  /* border-radius: 8px; */
  /* position: sticky; */
  top: 0;
  z-index: 10;
  font-weight: 900;
  /* width: calc(100% - 340px);  */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.message-list {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  /* margin-bottom: 7.5rem;  */
}

.message-list .date-separator { 
  text-align: center;
  margin: 10px 0;
  font-size: 14px;
  color: #fbfbfb;
  background-color: rgb(14, 159, 110);
    border-radius: 25px;
}

.button-separator {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px; /* Add space between buttons */
  margin: 8px 0;
  font-size: 14px;
  color: #fbfbfb;
  /* background-color: rgb(14, 159, 110); */
  /* padding: 10px; */
}

.custom-button {
  padding: 10px 20px;
  background-color: #b5babf;
  border: none;
  color: #000000;
  font-size: 12px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.custom-button:hover {
  background-color: rgb(103 115 111);
}


.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
  max-width: 70%;
  word-wrap: break-word;
}

.nocontactselected {
  font-size: larger;
  font-style: italic;
  color: green;
  padding: 100px;
  font-weight: 900;
}

.message.sent {
  background-color: #dcf8c6;
  float: right;
  clear: both;
  width: 20rem;
}
.message.received {
    background-color: rgba(191, 205, 202, .30980392156862746);
    color: #640000;
    float: left;
    clear: both;
    width: 20rem;
}

.message-text {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  align-content: space-between;
  justify-content: space-evenly;
  align-items: stretch;
}

.message-time {
  display: block;
  text-align: right;
  font-size: 12px;
  color: #999;
}

.message-input {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  /* position: fixed; */
  bottom: 0;
  width: -webkit-fill-available;
}

.message-input input[type="text"] {
  flex-grow: 1;
  border: none;
  padding: 10px;
  outline: none;
}

.message-input button {
  background-color: rgb(14, 159, 110);
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-left: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.message-input button:hover {
  background-color: rgb(14, 159, 110);
}

/* Highlight active and inactive tabs */
.radio-container label.active {
  font-weight: bold;
  color: #f8f8f8;
  background-color: rgb(14, 159, 110);
}
.back-to-contacts{
  display: none;
}

/* General Mobile Styles */
@media only screen and (max-width: 768px) {
  .whatsapp-web-container {
    flex-direction: column;
    height: 100vh;
  }
  .back-to-contacts {
    display: flex;
}

  .message-text {
    display: flex;
    flex-direction: column-reverse;
    width: 15rem;
  }

  /* Sidebar Styles */
  .whatsapp-sidebar {
    width: 100%;
    height: auto;
    border-right: none;
    position: relative;
    top: 0;
    bottom: auto;
    display: none;
  }

  .whatsapp-sidebar.active {
    display: block;
  }

  /* Sticky header for the sidebar */
  .whatsapp-sidebar h2 {
    font-size: 18px;
  }

  .whatsapp-message-area {
    margin-left: 0;
    width: 100%;
    padding: 0;
  }

  /* Message Area Styles */
  .whatsapp-message-header {
    width: 100%;
    padding: 10px 5px;
    position: sticky;
    background-color: rgb(14 159 110);
    display: flex;
    flex-direction: row;
    /* margin-right: 1rem; */
    justify-content: space-evenly;
        font-size: small;
  }

 

  .message-list {
      flex-grow: 1;
      overflow-y: auto;
      padding: 10px;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 8px;
      /* margin-bottom: 8.5rem; */
  
  }

  .message {
    max-width: 85%;
  }

  /* .message.sent,
  .message.received {
    width: auto;
    max-width: 80%;
  } */

  .message-input {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    /* position: fixed; */
    margin-bottom: 0rem;
    bottom: 0;
    /* width: 95%; */
    /* margin-top: 5px; */
  }
}

/* Mobile view specific classes */
.contact-list li {
  display: flex;
  justify-content: space-between;
}

.contact-list li .avatar {
  display: none;
}

.contact-list li .contact-info {
  flex-grow: 1;
  padding-left: 10px;
}
/* Mobile view specific classes */
@media only screen and (max-width: 768px) {
  .whatsapp-sidebar {
    display: none;
  }
  
  .whatsapp-sidebar.active {
    display: block;
  }
  
  .whatsapp-message-area {
    display: none;
  }
  
  .whatsapp-message-area.active {
    display: flex;
  }
}


/* DynamicModal.css */
.dynamic-modal {
  position: fixed;
  right: 20px;
  /* top: 0; */
  width: 250px;
  /* height: 100%; */
  background: white;
  /* border-left: 1px solid #ccc; */
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  z-index: 1000;
  border-radius: 10px;
  cursor: auto;
}

/* .modal-content {
  padding: 20px;
} */

.modal-header {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 10px;
  padding: 20px;
}

.modal-body {
  font-size: 0.8rem;
  margin-bottom: 10px;
  padding: 5px;
}

.modal-footer {
  font-size: 0.75rem;
  color: #555;
  padding: 5px;
}

.modal-buttons {
  margin-top: 10px;
  padding: 5px;
  cursor: auto;
}

.modal-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
  background-color:rgb(14, 159, 110);
  color: rgb(255, 255, 255);
  border: none;
  font-weight: 700;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px;
}


.modal-button:hover {
  background-color:rgb(14, 159, 112);
}

.modal-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.modal-footer-time{
  font-size: 0.7rem;
  color: #555;
  float: right;
}

hr {
  border: 0;
  border-top: 1px solid #eee;
  margin: 10px 0;
}


.timer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 50px; Adjust height as needed */
  margin-top: 1rem;
}

.timer {
  font-size: 15px;
  font-weight: bold;
  background-color: #f2f2f2;
  color: #333;
  padding: 4px;
  border-radius: 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


.forumcard {
  background-color: rgb(161 89 163);
  color: white;
}
.forumcard2 {
  background-color: rgb(238 169 65);
  color: white;
}
.forumcard3 {
  background-color:rgb(85 178 229);
  color: white;
}

.forumtext {
  color: rgb(161 89 163);
  opacity: 1;
  font-size: small;
}
.forumbutton {
  background-color: rgb(228 219 236);
  opacity: 1;
  font-size: small;
  color: black;
}
.forumaddbutton{
  margin-top: 1rem;
  height: 2.5rem;
  background-color:  rgb(161 89 163);
}

.forumapaginationbutton{
  /* margin-top: 1rem; */
  /* height: rem; */
  background-color:  rgb(161 89 163);
  color: white;
}

.forumstatusbutton{
  /* margin-top: 1rem; */
  /* height: rem; */
  color: #bb499e;
  /* color: white; */
}

.forumtitle {
    color: #bb499e;
    font-weight: 600;
    font-size: large;
    /* margin-left: 2rem; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.forumstatus {
  color: #bb499e;
  font-weight: 800;
  font-size: large;
  /* margin-left: 2rem; */
}

.Toastify__progress-bar--custom {
  background: #c80de1 !important; /* Change this to the desired color */
  color: #c80de1;
}

/* #bb499e */


.custom-tooltip {
  font-size: 14px; /* Example: Adjust the font size */
  padding: 10px; /* Example: Adjust the padding */
  cursor: pointer;
  color: grey;
}

/* Hover styling for the content inside the tooltip */
.custom-tooltip:hover .tooltip-content {
  color:  #c80de1;; /* Example: Change text color on hover */
}

/* Custom tick icon for the toast */
.Toastify__toast--custom .Toastify__toast-icon svg {
  fill: green; /* Default color */
}

.Toastify__toast--forum .Toastify__toast-icon svg {
  fill: rgb(161 89 163); /* Custom color for forum */
}

/* Custom class for forum text color */
.text-forum-500 {
  --text-opacity: 1;
  color: gray;
  cursor: pointer;
  margin-top:8px;
 color: rgba(158, 158, 158, var(--text-opacity));
  
}

/* Hover state for the custom class */
.text-forum-500:hover {
  color: #A159A3;
}


.text-list-500 {
  color: gray;
  cursor: pointer;
 display: flex;
 justify-content: flex-start;
 /* margin-left: 1rem; */
 font-weight: 600;
 /* margin-top: 1rem; */
 padding: 10px;
}

.text-list-500:hover {
  color: #A159A3;
}

